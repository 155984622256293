<div
#chartContainer class="chartContainer"

style="height: 1200px; background-color: #fffeff"
>

<!-- <pre>
  {{oc}}
</pre> -->

  <!-- <ng-container *ngFor="let x of oc">

    {{x}}
  </ng-container> -->


<div class="node"></div>
</div>
