export class GlobalConstants {
  public static branchNameTitleCase: string = 'Corporate';
  public static branchNameLowerCase: string = 'corporate';
  public static globalBranchDocId: string = 'corporate';
  public static rootCollectionAndBranchDoc = `cscBranchDirectory/corporate`;
  public static rootEmployeeCollection = 'cscEmployeeDirectory';
  public static branchCalendarSecretName = 'corporate-calendar-id';
  public static nationwideCalendarSecretName = "nationwide-calendar-id";
  public static twilioPhoneNumber = "8882705150"
  public static twilioPhoneNumberGCPSecretManager = "twilio-corporate-phone-number"
  public static branchCalendarTimezone = "America/Los_Angeles";
  public static corporateCalendarTimezone = "America/Los_Angeles";
  public static fullUrlIncSubDomain = "https://corporate.csc-usa.com";
  public static corporateMiscImages = "cscBranchDirectory/corporate/miscImages";
}
