import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-northern-virginia',
  templateUrl: './northern-virginia.component.html',
  styleUrls: ['./northern-virginia.component.css']
})
export class NorthernVirginiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
