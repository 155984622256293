import { Injectable, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { SnackbarService } from '../services/snackbar.service';
import { CreateService } from '../services/create.service';
import { Auth, onAuthStateChanged, signOut } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
interface User {
  uid: string;
  email: string;
  firstName?: string;
  lastName?: string;
  catchPhrase?: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  createdByPersonEmail = '';
  createdByPersonLegalNameFirst = '';
  createdByPersonLegalNameLast = '';
  createdByPersonCellPhone = '';
  createdByPersonUid = '';
  createdByPersonCscPhoneNumber = '';
  createdByPersonCscPhoneExt = '';
  createdByPersonJobTitle = '';
  createdByPersonAssignedRole = '';
  businessLicenseAccess;
  clientAccess;
  employeeAccess;
  user;
  personData;
  loggedInUid;
  isAuth = false;
  isAdmin = false;
  errorMsg;
  loggedinEmail;
  authRole;
  personAssignedRole;
  personAssignedRole2;
  customClaimsRole;
  loggedInProvider;
  currentUserUid;

  emailVerified: boolean;
  organizationUid: string;
  currentUser: any;
  currentUserObj: any;
  currentUserFirstName: any;
  currentUserLastName: any;
  currentUserEmail: any;
  currentOrganizationName: any;
  currentUserEmailVerified: any;
  userEmail: boolean;
  cDate: Date;
  cDateTimestamp: number;
  cUserPersonFirstName: any;
  cUserPersonLastName: any;
  cUserPersonOrganizationUid: any;
  cUserPersonEmail: any;
  currentOrganizationUid: any;
  currentUserRole: any;
  loggedInUserEmail: string;
  loggedInUserDisplayName: string;
  loggedInUserUid: string;
  userProfile$: any;
  user$: Observable<User | null>;

  constructor(
    private auth: Auth,
    private afs: Firestore,
    private router: Router,
    public snackbarService: SnackbarService,
    private createService: CreateService
  ) {

    this.user$ = new Observable((observer: any) =>
    onAuthStateChanged(auth, observer)
  );

    this.user$.subscribe(d => {
      if(d){
        localStorage.setItem('user', JSON.stringify(d));
        JSON.parse(localStorage.getItem('user'));
        this.loggedInUserEmail = d.email;
        sessionStorage.setItem('LoggedInUserEmail', JSON.stringify(d.email));
    }
  }
        );
    //  console.log('USER', this.user$);

    this.user$.subscribe((d) => {
      // console.log('USER D', d);
    });
  }


  logout(){
    sessionStorage.setItem('LoggedInUserEmail', null);
    sessionStorage.clear();
    // localStorage.clear();
    this.isAuth = false;
    // const snackClass = ['snackError'];
    // const message = `Signing Out: ${this.userEmail}`;
    // this.snackbarService.openSnackBar(message, snackClass);
    this.router.navigate(['/services']);
    signOut(this.auth);
  }
}
