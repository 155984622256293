import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ContactFormComponent } from './site/contact-form/contact-form.component';
import { OurServicesComponent } from './site/our-services/our-services.component';
import { HeaderComponent } from './homepage/header/header.component';
import { GoldStandardComponent } from './homepage/gold-standard/gold-standard.component';
import { OurExperienceComponent } from './homepage/our-experience/our-experience.component';
import { DhsCertifiedComponent } from './homepage/dhs-certified/dhs-certified.component';
import { EmailVerificationComponent } from './site/email-verification/email-verification.component';
import { EmailUnsubscribeComponent } from './site/email-unsubscribe/email-unsubscribe.component';
import { sectionMetadata } from './site/sections-metadata';
import { IndianapolisComponent } from './site/branch-promos/indianapolis/indianapolis.component';
import { RedirectGuard } from './auth/redirect.guard';
import { NorthernVirginiaComponent } from './site/branch-promos/northern-virginia/northern-virginia.component';
import { LousivilleComponent } from './site/branch-promos/lousiville/lousiville.component';
import { HoustonComponent } from './site/branch-promos/houston/houston.component';
import { PhoenixComponent } from './site/branch-promos/phoenix/phoenix.component';
 import { MaimiEventsComponent } from './site/branch-promos/maimi-events/maimi-events.component';
import { LosAngelesComponent } from './site/branch-promos/los-angeles/los-angeles.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    data: sectionMetadata.homePage,
    loadChildren: () =>
      import('./site/use-us/use-us.module').then((m) => m.UseUsModule),
  },

  {
    path: 'lacareers',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl:
        'https://www.teamworkonline.com/arenas-facilities-jobs/arenanetwork/losangeles-csc-jobs',
    },
  },

  {
    path: 'SuperBowlLVI',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://hportal.schedulingsite.com/verifyemail/5',
    },
  },

  // {
  //   path: 'la',
  //   loadChildren: () =>
  //     import('./site/branch-promos/los-angeles/los-angeles.module').then(
  //       (m) => m.LosAngelesModule
  //     ),
  // },
  // { path: 'la/login', component: LaLoginComponent },
  // { path: 'superbowlLVI', component: LaSbHiringEventComponent },
  // { path: 'superbowllvi', component: LaSbHiringEventComponent },
  // { path: 'superbowl56', component: LaSbHiringEventComponent },
  // { path: 'SUPERBOWLLVI', component: LaSbHiringEventComponent },
  // { path: 'SuperBowlLVI', component: LaSbHiringEventComponent },
  // { path: 'northernva', component: NorthernVirginiaComponent },
  // { path: 'LucasOilStadium', component: IndianapolisComponent },
  // { path: 'Derby2022', component: LousivilleComponent },
  // { path: 'nrgpark-events', component: HoustonComponent }, 
  { path: 'phoenixevents', component: PhoenixComponent },
  { path: 'miamievents', component: MaimiEventsComponent },
   // { path: 'NRGPARK-EVENTS', component: HoustonComponent },
   { path: 'superbowl', redirectTo:'apply', pathMatch:'full' },
   {path: 'supersunday', redirectTo:'apply', pathMatch:'full' },
  //  { path: 'supersunday', component: LosAngelesComponent },

  {
    path: 'apply',
    loadChildren: () =>
      import('./site/apply-branch-list/apply-branch-list.module').then(
        (m) => m.ApplyBranchListModule
      ),
  },
  {
    path: 'locations',
    loadChildren: () =>
      import('./site/branch-locations/branch-locations.module').then(
        (m) => m.BranchLocationsModule
      ),
  },

  {
    path: 'services',
    data: sectionMetadata.servicesPage,
    component: OurServicesComponent,
  },
  { path: 'email-verification', component: EmailVerificationComponent },
  { path: 'email-unsubscribe', component: EmailUnsubscribeComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'login', component: LoginComponent },
  { path: 'contact', component: ContactFormComponent },
  {
    path: 'esop',
    data: sectionMetadata.esopPage,
    loadChildren: () =>
      import('./site/esop/esop.module').then((m) => m.EsopModule),
  },
  // {
  //   path: 'newsfeed',
  //   data: sectionMetadata.newsfeedPage,
  //   loadChildren: () =>
  //     import('./site/newsfeed/newsfeed.module').then((m) => m.NewsfeedModule),
  // },
  {
    path: 'newsfeed',
    data: sectionMetadata.newsfeedPage,
    loadChildren: () =>
      import('./site/newsblast/newsblast.module').then(
        (m) => m.NewsblastModule
      ),
  },
  // {
  //   path: 'newsblast',
  //   loadChildren: () =>
  //     import('./site/newsblast/newsblast.module').then((m) => m.NewsblastModule),
  // },
  {path: 'safety-act',
  data: sectionMetadata.safetyActPage,
  loadChildren: () => import('./site/safety-act/safety-act.module').then(m => m.SafetyActModule)},
  {
    path: 'team',
    data: sectionMetadata.teamPage,
    loadChildren: () =>
      import('./site/executives/executives.module').then(
        (m) => m.ExecutivesModule
      ),
  },
  {
    path: 'experience-overview',
    data: sectionMetadata.experienceOverviewPage,
    loadChildren: () =>
      import('./site/experience-overview/experience-overview.module').then(
        (m) => m.ExperienceOverviewModule
      ),
  },
  {
    path: 'join-our-team',
    data: sectionMetadata.joinUsPage,
    loadChildren: () =>
      import('./site/join-us/join-us.module').then((m) => m.JoinUsModule),
  },
  {
    path: 'state-labor-laws',
    loadChildren: () =>
      import('./site/labor-laws/labor-laws.module').then(
        (m) => m.LaborLawsModule
      ),
  },

  {
    path: 'privacy-policy',
    data: sectionMetadata.privacyPolicyPage,
    loadChildren: () =>
      import('./site/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'services',
    data: sectionMetadata.servicesPage,
    loadChildren: () =>
      import(
        './site/use-us/use-us-services-dashboard/use-us-services-dashboard.module'
      ).then((m) => m.UseUsServicesDashboardModule),
  },

  // {path: 'Work2Win', loadChildren: () => import ('./site/work-to-win-giveaways/work-to-win-giveaways.module').then(m => m.WorkToWinGiveawaysModule)},
  // {path: 'work2win', loadChildren: () => import ('./site/work-to-win-giveaways/work-to-win-giveaways.module').then(m => m.WorkToWinGiveawaysModule)},
  // {path: 'worktowin', loadChildren: () => import ('./site/work-to-win-giveaways/work-to-win-giveaways.module').then(m => m.WorkToWinGiveawaysModule)},
  // {path: 'Work2win', loadChildren: () => import ('./site/work-to-win-giveaways/work-to-win-giveaways.module').then(m => m.WorkToWinGiveawaysModule)},
  // {path: 'WORK2WIN', loadChildren: () => import ('./site/work-to-win-giveaways/work-to-win-giveaways.module').then(m => m.WorkToWinGiveawaysModule)},
  // {path: 'Work2Win/Administrative', loadChildren: () => import ('./site/work-to-win-giveaways/administrative-work-to-win-dashboard/administrative-work-to-win-dashboard.module').then(m => m.AdministrativeWorkToWinDashboardModule)},
  // {path: 'work2win/administrative', loadChildren: () => import ('./site/work-to-win-giveaways/administrative-work-to-win-dashboard/administrative-work-to-win-dashboard.module').then(m => m.AdministrativeWorkToWinDashboardModule)},

  { path: '**', pathMatch: 'full', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {
  static components = [
    HomepageComponent,
    HeaderComponent,
    OurServicesComponent,
    LoginComponent,
    SignupComponent,
    GoldStandardComponent,
    OurExperienceComponent,
    DhsCertifiedComponent,
    EmailVerificationComponent,
    EmailUnsubscribeComponent,
    IndianapolisComponent,
    NorthernVirginiaComponent,
    LousivilleComponent,
    HoustonComponent,
    PhoenixComponent,
    MaimiEventsComponent,
    LosAngelesComponent
  ];
}
