import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { MaterialModule } from './material.module';
import {RouterModule} from '@angular/router';
import { PhonePipe} from '../pipes/phone-format.pipe';
import {
  ReactiveFormsModule,
  FormsModule
} from '@angular/forms';
import { ArchiveDialogComponent } from './archive-dialog/archive-dialog.component';
import { RestoreDialogComponent } from './restore-dialog/restore-dialog.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { AddDocumentDialogComponent } from './add-document-dialog/add-document-dialog.component';
import { PhoneMaskDirective } from './shared-directives/phone-mask.directive';
import { SocialMediaComponent } from '../site/social-media/social-media.component';
import { LayoutModule } from '@angular/cdk/layout';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FormDialogComponent } from './form-dialog/form-dialog.component';
import { ConfirmEqualValidatorDirective } from './shared-directives/confirm-equal-validator.directive';
import { FooterComponent } from '../site/footer/footer.component';
import { BranchLocationsComponent } from '../site/branch-locations/branch-locations.component';
import { MapInteractiveComponent } from '../site/map-interactive/map-interactive.component';
import { BranchListComponent } from '../site/branch-list/branch-list.component';
import { ServicesImageSectionComponent } from '../share/services-image-section/services-image-section.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { PrivacyNotificationDialogComponent } from './privacy-notification-dialog/privacy-notification-dialog.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { BackButtonWtihStateComponent } from './back-button-wtih-state/back-button-wtih-state.component';
import { D3OrgChartComponent } from '../site/d3-org-chart/d3-org-chart.component';

const components = [
  NavComponent,
  PhonePipe,
  SafeUrlPipe,
  ArchiveDialogComponent,
  RestoreDialogComponent,
  LoadingSpinnerComponent,
  DeleteDialogComponent,
  AddDocumentDialogComponent,
  PhoneMaskDirective,
  ConfirmEqualValidatorDirective,
  SocialMediaComponent,
  FormDialogComponent,
  FooterComponent,
  ServicesImageSectionComponent,
  DynamicFormComponent,
  FileUploadComponent,
  DynamicTableComponent,
  PrivacyNotificationDialogComponent,
  BackButtonWtihStateComponent,
  D3OrgChartComponent

];

const modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  LayoutModule,
  YouTubePlayerModule,
  GoogleMapsModule

];
@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components
  ]
})
export class SharedModule { }
