<mat-sidenav-container class="sidenav-container">
  <!-- SIDENAV -->
  <mat-sidenav *ngIf="isPhone || isTablet" #sidenav class="sidenav" fixedInViewport closed position="end">

    <mat-nav-list>
      <a mat-list-item routerLink="/"   (keyup)="sidenav.close"  (click)="sidenav.close()">
        <mat-icon>home</mat-icon>Home
      </a>
      <!-- <a mat-list-item routerLink="/about" (keyup)="showPromoFn"  (keyup)="showPromoFn"  (click)="sidenav.close()">
        <mat-icon>article</mat-icon> About CSC
      </a> -->
      <a mat-list-item routerLink="/experience-overview" (keyup)="sidenav.close"  (click)="sidenav.close()">
        <mat-icon></mat-icon> Experience Overview
      </a>
      <!-- <a mat-list-item routerLink="/team" (keyup)="sidenav.close"  (click)="sidenav.close()">
        <mat-icon></mat-icon> Team
      </a> -->
      <a mat-list-item routerLink="/newsfeed" (keyup)="sidenav.close"  (click)="sidenav.close()">
        <mat-icon></mat-icon> Newsfeed
      </a>
      <a mat-list-item routerLink="/services" (keyup)="sidenav.close"  (click)="sidenav.close()">
        <mat-icon></mat-icon> Services
      </a>
      <a mat-list-item routerLink="/locations" (keyup)="sidenav.close"  (click)="sidenav.close()">
        <mat-icon></mat-icon> Locations
      </a>
      <a mat-list-item routerLink="/apply" (keyup)="sidenav.close"  (click)="sidenav.close()">
        <mat-icon></mat-icon> Apply
      </a>
      <a mat-list-item routerLink="/esop" (keyup)="sidenav.close"  (click)="sidenav.close()">
        <mat-icon></mat-icon> Employee-Owned 
      </a>
     
      <a mat-list-item href="https://www.csc-fundraising.com" target="_blank" rel="noopener noreferrer" (keyup)="sidenav.close"  (click)="sidenav.close()">
        <mat-icon></mat-icon> Fundraising
      </a>
      <a mat-list-item routerLink="/safety-act" (keyup)="sidenav.close"  (click)="sidenav.close()">
        <mat-icon></mat-icon> Safety Act
      </a>
       
      <a mat-list-item  routerLink="/join-our-team" class="mobileJoinUsBtn" (keyup)="sidenav.close"  (click)="sidenav.close()">
        Join Us!</a>
      <!-- <a mat-list-item routerLink="/fundraising" (keyup)="showPromoFn"  (click)="sidenav.close()">
        <mat-icon>group_work</mat-icon> Fundraising
      </a> -->
      <!-- <a mat-list-item routerLink="/contact" (keyup)="showPromoFn"  (click)="sidenav.close()">
        <mat-icon>mail</mat-icon> Contact
      </a>
         <a mat-list-item href="https://hportalgrp.schedulingsite.com/SelectBranch" target="_blank" rel="noopener noreferrer" class="registerBtn">
          <mat-icon>how_to_reg</mat-icon>  Register</a> -->
      <!-- <ng-container *ngIf="authService.currentUserRole">
        <hr>
        <a mat-list-item routerLink="/admin" (keyup)="showPromoFn"  (click)="sidenav.close()">
          <mat-icon>admin_panel_settings</mat-icon> Admin
        </a>
      </ng-container> -->

      <!-- <ng-container *ngIf="afAuth.authState | async as user;else noUser">


        <a mat-list-item (keyup)="authService.logout"  (click)="authService.logout()" (keyup)="sidenav.close"  (click)="sidenav.close()">
          <mat-icon>lock_open</mat-icon> Logout
        </a>
      </ng-container> -->

    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- TOP TOOLBAR-->
    <mat-toolbar color="primary">
      <div class="cscNavLogo" routerLink="/home">
          <img class="navLogo" src="../../../assets/img/CSC-Footer-logo.png" alt="Contemporary Services Corporation Logo">
        <div>
          <span class="logo">CSC</span>
        </div>
      </div>
      <div class="socialMedia">
        <app-social-media></app-social-media>
      </div>
      <span class="fill-space"></span>
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (keyup)="sidenav.toggle"  (click)="sidenav.toggle()"
        *ngIf="isPhone || isTablet">
        <mat-icon class="mobileMenuBtn" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="mainNav" *ngIf="isLaptop ||isDesktop || isLargeDesktop">



        <button class="menuTriggerBtn" mat-button [matMenuTriggerFor]="aboutUsMenu">About Us <span class="material-icons">
          arrow_drop_down
          </span></button>
        <mat-menu #aboutUsMenu="matMenu" yPosition="below">
          <button mat-menu-item aria-label="Check out our experience!" [routerLink]="['/experience-overview']">Experience Overview</button>
          <!-- <button mat-menu-item aria-label="Meet our Team" [routerLink]="['/team']">Team</button> -->
        </mat-menu>
        <a mat-button aria-label="Click here for latest CSC News" routerLink="/newsfeed">Newsfeed</a>
        <a mat-button aria-label="Choose from our professional services" routerLink="/services">Services</a>
        <a mat-button aria-label="We're nationwide, find a branch near you" routerLink="/locations">Locations</a>
        <a mat-button aria-label="Be part of our team, apply today!" routerLink="/apply">Apply</a>
        <a mat-button aria-label="We're an employeed owned company, learn more" routerLink="/esop">Employee-Owned</a>
        <a mat-button href="https://www.csc-fundraising.com" target="_blank" rel="noopener noreferrer">Fundraising</a>
        <a mat-button aria-label="Check out our Safety Act" routerLink="/safety-act">Safety Act</a>
        <a mat-button aria-label="See the benefits of joining us" routerLink="/join-our-team" class="registerBtn">
          Join Us!</a>
        <!-- <a mat-button routerLink="/fundraising">Fundraising</a> -->
        <!-- <a mat-button routerLink="/services"> Services</a> -->
        <!-- <a mat-button routerLink="/contact"> Contact</a> -->
        <!-- <ng-container *ngIf="afAuth.authState | async as user;else noUser">

          <a mat-button  (keyup)="authService.logout"  (click)="authService.logout()"> <span class="material-icons">
            logout
            </span></a>
        </ng-container> -->

      </div>
    </mat-toolbar>
      <!-- TRANSCLUSION -->
      <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
