<div class="pageWrapper">
  <div class="pageHeader">
    <img src="https://firebasestorage.googleapis.com/v0/b/csc-usa-prod.appspot.com/o/cscBranchDirectory%2Fhouston%2Fvenues%2Fnrg%2FNRG_park_header.jpg?alt=media&token=50c55679-da6d-4add-a591-a13d5fad1c0a" alt="NRG Park,  Houston, TX" />
  </div>
  <div class="pageTitle">
    <h1>
      <span class="cscGold">CSC </span>
      <span class="cscMidnightBlueText">Houston</span>
    </h1>
    <span class="divider"> </span>
    <h2>
      <span class="cscMidnightBlueText ">We're </span
      ><span class="cscGold ">Hiring for NRG Park Events!</span>
    </h2>
  </div>
<div class="video">
<div id="locationsVideo" class="locationsVideoWrapper">
  </div>
</div>

  <div class="volumeButtons">
    <ng-container *ngIf="showMuteBtn">
  <button class="redBtn" mat-raised-button (click)="muteVolume()" id="muteVolume"><span class="material-icons">
    volume_up
    </span> Mute</button>
    </ng-container>
    <ng-container *ngIf="showUnmuteBtn">
   <button class="greenBtn" mat-raised-button (click)="unmuteVolume()" id="unmuteVolume"><span class="material-icons">
    volume_off
    </span> Unmute</button>
    </ng-container>
  </div>

  <!-- <div class="video">
    <iframe src="https://player.vimeo.com/video/695898281?h=4612fba397"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  </div> -->
  <div class="copyIntro">
    <p>"Join our team at NRG Park, and let's get to work! Now hiring event staff, ushers, ticket takers, and security for all major events this spring! Work this year's Offshore Technology Conference - on May 2 - 5, or be part of the Coldplay experience - on May 8."
    </p>
    <p>"CSC Houston is looking for new team members to join our team and show the world why we are The Leaders in Crowd Management. Incentive programs and bonuses are available; please inquire for more information. Apply using the link below and get scheduled today!"
    </p>


  </div>
  <div class="applyBtn">
    <a
      href="https://hportal.schedulingsite.com/verifyemailAdd/10"
      target="_blank"
      rel="noopener noreferrer"
      rel="noopener noreferrer"
    >
      <button class="cscGoldBtnInverse" mat-raised-button>
        APPLY NOW!
      </button>
    </a>
  </div>
</div>
