<div class="pageWrapper">
  <div class="pageHeader">

    <div class="container">

      <input type="radio" id="i1" name="images" checked />
      <input type="radio" id="i2" name="images" />
      <input type="radio" id="i3" name="images" />
      <input type="radio" id="i4" name="images" />
      <input type="radio" id="i5" name="images" />

      <div class="slide_img" id="one">

          <img src="../../../../assets/img/branches/louisville/IMG_7040.jpg" alt="Photo of Churchill Downs Spires.">

            <label class="prev" for="i5"><span>&#x2039;</span></label>
            <label class="next" for="i2"><span>&#x203a;</span></label>

      </div>

      <div class="slide_img" id="two">

          <img loading="lazy" src="../../../../assets/img/branches/louisville/IMG_7208.jpg" alt="Photo of horses racing." >

            <label class="prev" for="i1"><span>&#x2039;</span></label>
            <label class="next" for="i3"><span>&#x203a;</span></label>

      </div>

      <div class="slide_img" id="three">
          <img loading="lazy" src="../../../../assets/img/branches/louisville/IMG_7223.jpg" alt="Photo of horse warming up before race.">

            <label class="prev" for="i2"><span>&#x2039;</span></label>
            <label class="next" for="i4"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="four">
          <img loading="lazy" src="../../../../assets/img/branches/louisville/IMG_7395.jpg" alt="Photo of Churchill Downs grandstand.">

            <label class="prev" for="i3"><span>&#x2039;</span></label>
            <label class="next" for="i5"><span>&#x203a;</span></label>
      </div>

      <div class="slide_img" id="five">
          <img loading="lazy" src="../../../../assets/img/branches/louisville/IMG_7480.jpg" alt="Photo of Churchill Downs bronze horse, entrance.">

            <label class="prev" for="i4"><span>&#x2039;</span></label>
            <label class="next" for="i1"><span>&#x203a;</span></label>

      </div>

      <div id="nav_slide">
        <label for="i1" class="dots" id="dot1"></label>
        <label for="i2" class="dots" id="dot2"></label>
        <label for="i3" class="dots" id="dot3"></label>
        <label for="i4" class="dots" id="dot4"></label>
        <label for="i5" class="dots" id="dot5"></label>
      </div>

    </div>
  </div>

<div class="pageTitle">
    <h1>
      <span class="cscGold">CSC </span>
      <span class="cscMidnightBlueText">Louisville</span>
    </h1>
    <span class="divider"> </span>
    <h2>
      <span class="cscMidnightBlueText ">We're </span
      ><span class="cscGold ">Hiring!</span>
    </h2>
  </div>

  <div class="derbyLogo">
    <img src="../../../../assets/img/branches/louisville/Kentucky_Derby_148.jpg" alt="Graphic for the 148th Kentucky Derby.">
  </div>
  <div class="copyIntro">
    <p>Work Kentucky Derby 148! CSC Louisville is now hiring for the 2022 Kentucky Derby for multiple positions including ticket taking, guest services, ushering, and security. We will be holding an open hiring day on March 19, 2022 from 10:00 am to 3:00 pm. Come join us and experience the 'Fastest 2 Minutes in Sports'.
    </p>
    <div class="whereWhen">
        <p class="where"><span class="pHeading">Where:</span> Churchill Downs 700 Central Ave, Louisville KY, 40208</p>
    <p class="when">
      <span class="pHeading">When:</span>
      March 19th From 10:00 am to 3:00 pm
    </p>
    </div>


  </div>
  <div class="applyBtn">
    <a
      href="https://hportal.schedulingsite.com/verifyemailAdd/46"
      target="_blank"
      rel="noopener noreferrer"
      rel="noopener noreferrer"
    >
      <button class="cscGoldBtnInverse" mat-button>
        APPLY NOW!
      </button>
    </a>
  </div>
</div>


