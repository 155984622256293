import {
  OnChanges,
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { type } from 'os';
//  import  {OrgChart}  from 'd3-org-chart';
//  import * as d3 from "d3";
declare let d3: any;

@Component({
  selector: 'app-d3-org-chart',
  templateUrl: './d3-org-chart.component.html',
  styleUrls: ['./d3-org-chart.component.css'],
})
export class D3OrgChartComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('chartContainer') chartContainer: ElementRef;
 @Input() data: any[];
  @Input() execs: any[];
  public chart;
  public compact = 0;
  oc: any;

  constructor() {}

  ngOnInit() {

console.table('EXECS', this.data)



// d3.json(this.data)
// .then(data=>{

// new OrgChart()
//    .container('.chart-container')
//    .data(data)
//    .svgWidth(window.innerWidth)
//    .svgHeight(window.innerWidth)
//    .initialZoom(0.6)
//    .onNodeClick(d=> console.log(d+' node clicked'))
//    .render()


// })


this.updateChart()
// const orgChart = d3.select('#org-chart')
// .append('svg')
// .attr('width', 1000)
// .attr('height', 1000);


// const root = d3.hierarchy(this.data, (d) => d.children);
// const treeLayout = d3.tree()
// .size([500, 500]);

// const nodes = treeLayout(root);

// const nodeGroup = orgChart.selectAll('.node')
// .data(nodes.descendants())
// .enter()
// .append('g')
// .attr('transform', (d) => `translate(${d.x}, ${d.y})`);

// nodeGroup.append('circle')
// .attr('r', 10);

// nodeGroup.append('text')
// .text((d) => d.data.name)
// .attr('text-anchor', 'middle');

// const link = orgChart.selectAll('.link')
// .data(nodes.links())
// .enter()
// .append('path')
// .attr('d', d3.linkVertical()
// .x((d) => d.x)
// .y((d) => d.y));

  }

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new d3.OrgChart();
    }
    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }
  updateChart() {
    if (!this.data) {
      return;
    }
    if (!this.chart) {
      return;
    }

    let root;

   this.oc = this.chart
      .container(this.chartContainer.nativeElement)
      .data(this.data)
      .svgWidth(500)
      .initialZoom(1.4)
      .nodeHeight(d => 110)
      .nodeWidth(d => 210)
      .onNodeClick((d) => console.log(d + ' node clicked'))
      // .buttonContent(({ node, state }) => {
      //   console.log('node', typeof node)
      //   console.log('state', typeof state)



      //   return `<div style="px;color:#716E7B;border-radius:5px;padding:4px;font-size:10px;margin:auto auto;background-color:white;border: 1px solid #E4E2E9"> <span style="font-size:9px">${
      //     node.children
      //       ? `<i class="fas fa-angle-up"></i>`
      //       : `<i class="fas fa-angle-down"></i>`
      //   }</span> ${node.data._directSubordinates}  </div>`;
      // })
      // .linkUpdate(function (d, i, arr) {
      //   console.log('D', d);
      //   console.log('D', typeof d._children);
      //   d3.select(this)
      //     .attr('stroke', (d) =>
      //       d.data._upToTheRootHighlighted ? '#152785' : '#E4E2E9'
      //     )
      //     .attr('stroke-width', (d) =>
      //       d.data._upToTheRootHighlighted ? 5 : 1
      //     );

      //   if (d.data._upToTheRootHighlighted) {
      //     d3.select(this).raise();
      //   }
      // })
      .nodeContent(function (d, i, arr, state) {
        console.log('D', d)
        console.log('Data ID', d.data.id)
        d.data.expanded = true
        if (d._children) {
          d._children.forEach(d => {
            d.expanded = true
          })
      }


        // Expand children
        // d.children = d._children;
        // d._children = null;

        // Set each children as expanded
        // d.children.forEach(({
        //     data
        // }) => data.expanded = true)

        console.log('D', d)
        console.log('D', d)
        console.log('OC', d.data.name)
        this.oc = d.data.name;
        const color = '#FFF';
        return `
        <div style="font-family: 'Montserrat', sans-serif;background-color:${color}; margin-top:-1px; margin-left:-1px;width:${d.width}px;height:${d.height}px;border-radius:10px;border: 1px solid #E4E2E9; border-top: 40px solid  #D4E2F4">

          <div style="top: 10px; font-size:15px;color:#08011E;margin-top:-27px; text-align: center; ">
          <p>
          ${
            d.data.name
          }
          </p>
         </div>
          <div style="color:#716E7B;margin-top:25px;font-size:12px;text-align: center; display: grid; place-items: center;">
          <p style="font-size:12px">
          ${
            d.data.positionName
          }
          </p>
          </div>
       </div>
`;
      })
      .render();

    console.log(this.chart);
  }
  //  collapse(d) {
  //   if (d.children && d.depth > 0) {
  //     d._children = d.children;
  //     d._children.forEach((d) => this.collapse(d));
  //     d.children = null;
  //     return;
  //   }
  // }
}


