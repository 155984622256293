

<a  (click)="backButtonWithState()" class="goBackLink">
  <span class="material-icons-outlined backIcon">
  arrow_back
  </span>
<p class="backText">
 Back
</p>
</a>
