import {
  Component
} from '@angular/core';

@Component({
  selector: 'app-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.css']
})
export class ArchiveDialogComponent {}
