import {Component, OnInit } from '@angular/core';
import Player from '@vimeo/player';
import { interval, map } from 'rxjs';
import { ReadService } from 'src/app/services/read.service';
@Component({
  selector: 'app-maimi-events',
  templateUrl: './maimi-events.component.html',
  styleUrls: ['./maimi-events.component.css']
})
export class MaimiEventsComponent implements OnInit {
  player: any;
  // player = new Player.player();
  // videoId = 770891963;
  videoId: any;
  videoPlayer: HTMLElement;
  targets: NodeListOf<HTMLImageElement>;
  videoOptions: any;
  muteBtn;
  showMuteBtn = false;
  showUnmuteBtn = true;
  eventAssets: any;
  showVideoSection = false;
  eventStartsIn: any;
  eventCountdownClock: any;
  showApplyConent = false;
  diff: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  showClock = true;
  constructor(
    private readService: ReadService
  ) {

    this.eventAssets = this.readService.returnObservableWhereFn(
      `cscBranchDirectory/corporate/branchHiringEvents`,
      'docId',
      'miami'
    );
  }

   ngOnInit(): void {
    this.fetchVideoIdFn();
    this.countDownClockFn();
    setTimeout(() => {
      this.showApplyConent = true;
    }, 700);
  }


  countDownClockFn(){
    const t = this.readService.returnObservableWhereFn(
      'cscBranchDirectory/miami/eventCountdownClock',
      'eventTitle',
      'countdownClockTimestamp'
    );

    t.subscribe(d => {
      console.log(d)
      console.log(...d)
      const [date] = [...d];
      console.log('Date', date);
      console.log('Date TS', date.timestamp);
  this.eventStartsIn = date.timestamp;

    });

    this.eventCountdownClock = interval(1000).pipe(
      // map((x) => {this.diff = (Date.parse(this.trialEndsAt) - Date.parse(new Date().toString()));
        map((x) => {this.diff = (this.eventStartsIn - new Date().valueOf());

      })).subscribe((x) => {
       console.log('X DIFF', this.diff);
              this.days =    this.getDays(this.diff);
          //    this.days =   parseFloat(this.days);
          console.log('X days', this.days);
              this.hours =   this.getHours(this.diff);
           //   this.hours =  parseFloat(this.hours);
           console.log('X hours', this.hours);
              this.minutes = this.getMinutes(this.diff);
           //   this.minutes =  parseFloat(this.minutes);
           console.log('X minutes', this.minutes);
              this.seconds = this.getSeconds(this.diff);
           //   this.seconds =  parseFloat(this.seconds);
           console.log('X seconds', this.seconds);
          if(this.days < 0 && this.hours < 0 && this.minutes < 0 && this.seconds < 0){
            this.showClock = false;

            this.stopTimerFn();
           // setTimeout(() => { interval(this.eventCountdownClock) }, 3000);

              return;
          }
           console.log('Days', +this.days);
              // console.log('Hrs',  +this.hours);
              // console.log('Mins', +this.minutes);
              // console.log('Secs', +this.seconds);
          });
  }

  stopTimerFn(){
    //  clearTimeout(this.eventCountdownClock);
      setTimeout(() => { interval(this.eventCountdownClock) }, 3000);
     }

    getDays(t) {
        return +Math.floor( t / (1000 * 60 * 60 * 24) );
    }

    getHours(t) {
        return Math.floor( (t / (1000 * 60 * 60)) % 24 );
    }

    getMinutes(t) {
        return Math.floor( (t / 1000 / 60) % 60 );
    }

    getSeconds(t) {
        return Math.floor( (t / 1000) % 60 );
    }

  async fetchVideoIdFn(){
    const v = await this.readService.returnPromiseWhereFn(
      `cscBranchDirectory/corporate/branchHiringEvents`,
      'docId',
      'miami'
    );

    const [a] = [...v];

    this.videoOptions = {
           id:  a.videoId,
          responsive: true,
           controls: true,
         };
         console.log('videoId ', a.videoId);
    if(a.videoId){

      this.showVideoSection = true;
      setTimeout(() => {
        console.log('Video Options ', this.videoOptions);

         this.player = new Player('locationsVideo', this.videoOptions);
      this.player.play();

  this.player.getDuration().then((duration) => {
      console.log('VIMEO duration', duration);
    });

    document
      .getElementById('locationsVideo')
      .addEventListener('ended', (event) => {
       console.log('VIMEO EVENT', event);
      });

    this.player.getEnded().then((ended) => {
         console.log('VIMEO ENDED', ended);    }).catch(function(error) {
          console.log('VIMEO ERROR', error);
    });
    setTimeout(() => {
      this.intersectionFn();
    }, 1000);
    const locationsVideo = document.querySelector('#locationsVideo');

    document
      .querySelector('#volume')
      .addEventListener('click', () =>
        this.player.setMuted(false)
      );

      }, 500);
    }
  }

  muteVolume() {
    this.player.setMuted(true);
    this.showMuteBtn = false;
    this.showUnmuteBtn = true;
  }
  unmuteVolume() {
    this.player.setMuted(false);
    this.showMuteBtn = true;
    this.showUnmuteBtn = false;
  }

  intersectionFn() {
    this.targets = document.querySelectorAll('.locationsVideoWrapper');
    //  console.log('Targets',  this.targets);

    const lazyLoad = (target) => {
      const io = new IntersectionObserver((entries, observer) => {
        //  console.log('Entries', entries);
        // console.log('Observer', observer);
        entries.forEach((entry) => {
          // console.log('😍');
          if (entry.isIntersecting) {
            //   console.log('intersecting', entry);
            this.player = new Player('locationsVideo', this.videoOptions);
            this.muteBtn = 'Unmute';
            this.player.play();
            this.player.setMuted(true);
            // const img = entry.target;
            // const src = img.getAttribute('data-lazy');
            // img.setAttribute('src', src);
            // img.classList.add('fade');
            // observer.disconnect();
          } else {
            this.player.pause();
          }
        });
      });
      io.observe(target);
    };
    this.targets.forEach(lazyLoad);
  }
}
