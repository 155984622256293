import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lousiville',
  templateUrl: './lousiville.component.html',
  styleUrls: ['./lousiville.component.css']
})
export class LousivilleComponent implements OnInit {

  ngOnInit(): void {
    this.start();
   }

   start(){
    let i = 0;
    function Move(){
      i = (i % 5) + 1; // 5 is the Number of image in slider
      const d = document.getElementById('i' + i) as HTMLInputElement;
      d.checked  = true ;
    }
    setInterval(Move, 3000); // change img in 3 sec
    }
}
