<div>
  <!-- <button type="submit" (click)="onClicked()">Click Me</button> -->
</div>


<form [formGroup]="form">
  <div>
    <ng-content select=".formTitle"></ng-content>
  </div>
  <div [class]="formCSSClass">

  <ng-template ngFor let-input [ngForOf]="formData">
    <ng-container [ngSwitch]="input.controlType">
      <!-- handling text type inputs -->

        <ng-template [ngSwitchCase]="'text'">
          <div [class]="input.cssWrapperClass">
            <mat-form-field appearance="outline" class="">
              <mat-label> {{input.controlLabel}}</mat-label>
              <input
              matInput
              [formControlName]="input.controlName"
              [type]="input.valueType"
              [name]="input.controlName"
              [required]="input.validators.required"
              [placeholder]="input.placeholder"
              [minlength]="input.validators.minlength"
              [maxlength]="input.validators.maxlength" />
              <mat-hint>{{input.controlHint}}</mat-hint>
              <mat-error *ngIf="formService.formErrors[input.controlName]">{{formService.formErrors[input.controlName]}}</mat-error>
              <!-- <div class="error" *ngIf="form.get(input.controlName).invalid &&
                                      (form.get(input.controlName).dirty || form.get(input.controlName).touched)">
                <div *ngIf="form.get(input.controlName).errors.required">Input required</div>
                <div *ngIf="form.get(input.controlName).errors.minlength">Minimum length is {{input.validators.minlength}}
                </div>
              </div> -->
            </mat-form-field>
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="'email'">
          <div [class]="input.cssWrapperClass">
            <mat-form-field appearance="outline" class="">
              <mat-label> {{input.controlLabel}}</mat-label>
              <input
              matInput
              [formControlName]="input.controlName"
              [type]="input.valueType"
              [name]="input.controlName"
              [required]="input.validators.required"
              [pattern]="input.validators.pattern"

              [placeholder]="input.placeholder"
              [minlength]="input.validators.minlength"
              [maxlength]="input.validators.maxlength" />
              <mat-hint>{{input.controlHint}}</mat-hint>
              <mat-error *ngIf="formService.formErrors[input.controlName]">{{formService.formErrors[input.controlName]}}</mat-error>

            </mat-form-field>
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="'textarea'">
          <div [class]="input.cssWrapperClass">
            <mat-form-field appearance="outline" [class]="input.cssMatFormFieldClass">
              <mat-label> {{input.controlLabel}}</mat-label>
              <textarea
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              [cdkAutosizeMinRows]="input.minRows"
              [cdkAutosizeMaxRows]="input.maxRows"
                [type]="input.valueType"
                [name]="input.controlName"
                [class]="input.cssTextareaClass"
                [placeholder]="input.placeholder"
                [formControlName]="input.controlName"
                [required]="input.validators.required"
                [minlength]="input.validators.minlength"
                matInput></textarea>
                <mat-error *ngIf="formService.formErrors[input.controlName]">{{formService.formErrors[input.controlName]}}</mat-error>
            </mat-form-field>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'select'">
          <!-- <span #optionLoop [innerText]="input.options?.length"></span> -->
            <!-- <div *ngFor="let option of formData | async">
              {{fControlName}}
            </div> -->

          <div  #selectOptions [id]="input.controlName" [class]="input.cssWrapperClass">
            <!-- <ng-container *ngFor="let x of selectArray">
              <pre  >Array: {{x}}</pre>
            </ng-container> -->
            <ng-container >
             <!-- <span  >Current RES: {{res}}</span> -->
             <!-- <span  >Current Array: {{currentArrayItem}}</span>
             <span  >Current i: {{productList$[i]}}</span> -->

             <!-- <button (click)="fetchCol([input.controlName], [input.options])">FETCH</button> -->
            <mat-form-field appearance="outline" class="pageCopy">
              <mat-label> {{input.controlLabel}}</mat-label>
              <!-- <input
              [formControlName]="input.controlName"  [type]="input.valueType"
              #inp
              matInput
                (input)="fetchDataFn(inp.value, [input.controlName],[input.options])"
             />
                <mat-option   value="">{{input.placeholder}}</mat-option>
                <ng-container *ngIf="input.controlName">
                  <div  *ngFor="let option of filteredArray$ | async" [>{{option.optionName}}
                  </div>
                </ng-container> -->

              <input
              [formControlName]="input.controlName"
              [type]="input.valueType"
              [required]="input.validators.required"
              [minlength]="input.validators.minlength"
              [maxlength]="input.validators.maxlength"
              matInput
              #matchArryName
              (focus)="fetchCol([input.controlName], [input.options])"
              [id]="input.controlName"
              [matAutocomplete]="a" />
              <mat-autocomplete  [displayWith]="displayFn"
              #a="matAutocomplete">
                <!-- <mat-option   value="">{{input.placeholder}}</mat-option> -->
                <ng-container >
                  <mat-option  *ngFor="let option of filteredArray$ | async " [value]="option">{{option.optionName}}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
              <!-- <mat-select [formControlName]="input.controlName" [id]="input.controlName"
                [required]="input.validators.required">
                <mat-option value="">{{input.placeholder}}</mat-option>
                <mat-option *ngFor="let option of input.options" [value]="option.optionName">{{option.optionName}}
                </mat-option>
              </mat-select> -->
              <mat-error *ngIf="formService.formErrors[input.controlName]">
                {{formService.formErrors[input.controlName]}}</mat-error>
            </mat-form-field>
            </ng-container>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'date'">
          <div [class]="input.cssWrapperClass">

            <mat-form-field appearance="outline">
              <input
              [min]="input.minDate"
              matInput
              readonly
                (focus)="picker.open()"
                [matDatepicker]="picker"
                [type]="input.valueType"
                [placeholder]="input.controlLabel"
                [formControlName]="input.controlName"
                [required]="input.validators.required"
                [maxlength]="input.validators.maxlength"
                [minlength]="input.validators.minlength">
                <mat-hint>e.g. 01/30/2022</mat-hint>
                <mat-error *ngIf="formService.formErrors[input.controlName]">
                  {{formService.formErrors[input.controlName]}}</mat-error>
              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker disabled="false" #picker></mat-datepicker>

            </mat-form-field>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'checkbox'">
          <div [class]="input.cssWrapperClass">
            <mat-checkbox #inputValue [formControlName]="input.controlName" [name]="input.controlName">
              {{input.controlLabel}}</mat-checkbox>
            <mat-error *ngIf="formService.formErrors[input.controlName]">
              {{formService.formErrors[input.controlName]}}</mat-error>
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="'numberOnly'">
          <div [class]="input.cssWrapperClass">
            <mat-form-field appearance="outline" class="">
              <mat-label> {{input.controlLabel}}</mat-label>
              <input #inputValue matInput
               (keypress)="formService.numberOnly($event)"
                [formControlName]="input.controlName"
                [type]="input.valueType"
                [name]="input.controlName"
                [required]="input.validators.required"
                [minlength]="input.validators.minlength"
                [placeholder]="input.placeholder"
                [maxlength]="input.validators.maxlength" />
              <mat-error *ngIf="formService.formErrors[input.controlName]">
                {{formService.formErrors[input.controlName]}}</mat-error>
            </mat-form-field>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'radio'">
          <!-- <mat-form-field appearance="outline" class="pageCopy"></mat-form-field> -->
        <div [class]="input.cssWrapperClass">
          <h3>  {{input.placeholder}}</h3>
          <p [class]="input.legalsCSSClass">{{input.legalNotice}}</p>
  <mat-radio-group
          [formControlName]="input.controlName"
          [required]="input.validators.required"
      >
            <mat-radio-button *ngFor="let option of input.options"
            [value]="option.value"
            >{{option.optionName}}
            </mat-radio-button>
            <mat-error *ngIf="formService.formErrors[input.controlName]">{{formService.formErrors[input.controlName]}}</mat-error>
          </mat-radio-group>
        </div>
          <!-- <div *ngFor="let option of input.options" class="form-group row">
            <input
            [formControlName]="input.controlName"
            [type]="input.controlType"
            [name]="input.controlName"
            [value]="option.value"
            [required]="input.validators.required">
            <mat-label> {{option.optionName}}</mat-label>
          </div> -->
        </ng-template>
    </ng-container>
  </ng-template>
</div>
  <div class="">
    <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid" (click)="onClicked()">Submit</button>
  </div>
</form>
