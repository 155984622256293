import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pluck } from 'rxjs/operators';
import { CreateService } from 'src/app/services/create.service';
import { SEOService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.css']
})
export class OurServicesComponent implements OnInit {

  readonly articles$ = this.route.data.pipe(pluck('servicesPage'));

  constructor(
    private createService: CreateService,
    private router: Router,
    private seoService: SEOService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    // this.createService.setPageTitleFn('CSC Services');
    const { meta } = this.route.snapshot.data;
    this.seoService.updateTitle(meta.title);
    this.seoService.updateDescription(meta.description);
  }
}
