import { Component, OnInit } from '@angular/core';
import Player from '@vimeo/player';
@Component({
  selector: 'app-houston',
  templateUrl: './houston.component.html',
  styleUrls: ['./houston.component.css']
})
export class HoustonComponent implements OnInit {
  player: any;
  // player = new Player.player();
  videoId = 695898281;
  videoPlayer: HTMLElement;
  targets: NodeListOf<HTMLImageElement>;
  videoOptions: any;
  muteBtn;
  showMuteBtn = false;
  showUnmuteBtn = true;
  constructor() { }

  ngOnInit(): void {
    this.videoOptions = {
      id: 695898281,
      responsive: true,
      controls: true,
    };
    this.player = new Player('locationsVideo', this.videoOptions);
    this.player.play();

    this.player.getDuration().then((duration) => {
      // console.log('VIMEO duration', duration);
    });

    document
      .getElementById('locationsVideo')
      .addEventListener('ended', (event) => {
        // console.log('VIMEO EVENT', event);
      });

    this.player.getEnded().then((ended) => {
      //    console.log('VIMEO ENDED', ended);    }).catch(function(error) {
      //     console.log('VIMEO ERROR', error);
    });
    setTimeout(() => {
      this.intersectionFn();
    }, 1000);
    const locationsVideo = document.querySelector('#locationsVideo');

    document
      // select the volume button
      .querySelector('#volume')
      // bind a click event
      .addEventListener('click', () =>
        // unmute the video
        this.player.setMuted(false)
      );
  }

  muteVolume() {
    this.player.setMuted(true);
    this.showMuteBtn = false;
    this.showUnmuteBtn = true;
  }
  unmuteVolume() {
    this.player.setMuted(false);
    this.showMuteBtn = true;
    this.showUnmuteBtn = false;
  }

  intersectionFn() {
    this.targets = document.querySelectorAll('.locationsVideoWrapper');
    //  console.log('Targets',  this.targets);

    const lazyLoad = (target) => {
      const io = new IntersectionObserver((entries, observer) => {
        //  console.log('Entries', entries);
        // console.log('Observer', observer);
        entries.forEach((entry) => {
          // console.log('😍');
          if (entry.isIntersecting) {
            //   console.log('intersecting', entry);
            this.player = new Player('locationsVideo', this.videoOptions);
            this.muteBtn = 'Unmute';
            this.player.play();
            this.player.setMuted(true);
            // const img = entry.target;
            // const src = img.getAttribute('data-lazy');
            // img.setAttribute('src', src);
            // img.classList.add('fade');
            // observer.disconnect();
          } else {
            this.player.pause();
          }
        });
      });
      io.observe(target);
    };
    this.targets.forEach(lazyLoad);
  }
}
