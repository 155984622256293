import { Component, OnInit } from '@angular/core';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { FormDialogComponent } from 'src/app/shared/form-dialog/form-dialog.component';
import { CreateService } from 'src/app/services/create.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UseUsService } from '../use-us/use-us.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  isSubmitted = false;
  successfulRegistration = false;
  successMessage: string;
  constructor(
    private useUs: UseUsService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  newsletterSignUp() {
    //  console.log('Emitted Data', docId);
    // this.isShowUpdateEmployeeForm = true;
    const dialogRef = this.dialog.open(FormDialogComponent, {
      panelClass: 'editDialog',
      data: {
        formConfig: this.useUs.newsletterSignUpEmailFn(),
        class: 'newsletterSignUp',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
      //  console.log('Form Data', res);
        if (!res.data || res === undefined) {
       //   console.log('Form Data UNDEFINED');
          return;
        } else {
       //   console.log('SIGN UP res.data', res.data);

          this.isSubmitted = true;
          try {
            const resEmail = res.data.personEmail;
            const email = resEmail.toLowerCase();
            // const formData = {
            //   personEmail: email
            // }
          //  console.log('SIGN UP EMAIL', email);
            const signUp = `d ${new Date().getTime() / 1000}`;;
            // const d  = {
            //   docId: signUp,
            //   signUp: email,
            //   personEmail: email,
            //   emailVerificationUrl: `http://localhost:4200/email-verification?email=${email}`,
            //   unsubscribeUrl: `http://localhost:4200/email-unsubscribe?s=${signUp}`
            // }
            const d = {
              docId: signUp,
              signUp: email,
              personEmail: email,
              emailVerificationUrl: `https://csc-usa.com/email-verification?email=${email}`,
              unsubscribeUrl: `https://csc-usa.com/email-unsubscribe?s=${signUp}`,
            };

          //  console.log('SIGN UP DATA', d);
            const createEvent = httpsCallable(getFunctions(),'newsletterSignUp');
            createEvent(d).then((result) => {
          //    console.log('CREATE SUSBSCRIPTION', result.data.status);

              if (result.data === 200) {
                this.successfulRegistration = true;
                this.isSubmitted = false;
                this.successMessage = `You're in! A confirmation email has been sent to ${email} `;
              }
              if (result.data === 1) {
                this.successfulRegistration = true;
                this.isSubmitted = false;
                // this.successMessage = result.data;
              }
              if (result.data === 401) {
                const snackClass = ['snackError'];
                const message = `Subscription failed.`;
                this.snackbarService.openSnackBar(message, snackClass);
              }
            });
          } catch (e) {
         //   console.log('ERROR', e);
          }
        }
      });
  }
}
