import {Component, OnInit } from '@angular/core';
import Player from '@vimeo/player';
import { ReadService } from 'src/app/services/read.service';
@Component({
  selector: 'app-phoenix',
  templateUrl: './phoenix.component.html',
  styleUrls: ['./phoenix.component.css']
})
export class PhoenixComponent implements OnInit {
  player: any;
  // player = new Player.player();
  // videoId = 770891963;
  videoId: any;
  videoPlayer: HTMLElement;
  targets: NodeListOf<HTMLImageElement>;
  videoOptions: any;
  muteBtn;
  showMuteBtn = false;
  showUnmuteBtn = true;
  stadiumAssets: any;
  showVideoSection = false;
  constructor(
    private readService: ReadService
  ) {

    this.stadiumAssets = this.readService.returnObservableWhereFn(
      `cscBranchDirectory/corporate/branchHiringEvents`,
      'docId',
      'phoenix'
    );
  }

   ngOnInit(): void {
    this.fetchVideoIdFn();
  }

  async fetchVideoIdFn(){
    const v = await this.readService.returnPromiseWhereFn(
      `cscBranchDirectory/corporate/branchHiringEvents`,
      'docId',
      'phoenix'
    );

    const [a] = [...v];

    this.videoOptions = {
           id:  a.videoId,
          responsive: true,
           controls: true,
         };
         console.log('videoId ', a.videoId);
    if(a.videoId){

      this.showVideoSection = true;
      setTimeout(() => {
        console.log('Video Options ', this.videoOptions);

         this.player = new Player('locationsVideo', this.videoOptions);
      this.player.play();

  this.player.getDuration().then((duration) => {
      console.log('VIMEO duration', duration);
    });

    document
      .getElementById('locationsVideo')
      .addEventListener('ended', (event) => {
       console.log('VIMEO EVENT', event);
      });

    this.player.getEnded().then((ended) => {
         console.log('VIMEO ENDED', ended);    }).catch(function(error) {
          console.log('VIMEO ERROR', error);
    });
    setTimeout(() => {
      this.intersectionFn();
    }, 1000);
    const locationsVideo = document.querySelector('#locationsVideo');

    document
      .querySelector('#volume')
      .addEventListener('click', () =>
        this.player.setMuted(false)
      );

      }, 2000);

    }



  }

  muteVolume() {
    this.player.setMuted(true);
    this.showMuteBtn = false;
    this.showUnmuteBtn = true;
  }
  unmuteVolume() {
    this.player.setMuted(false);
    this.showMuteBtn = true;
    this.showUnmuteBtn = false;
  }

  intersectionFn() {
    this.targets = document.querySelectorAll('.locationsVideoWrapper');
    //  console.log('Targets',  this.targets);

    const lazyLoad = (target) => {
      const io = new IntersectionObserver((entries, observer) => {
        //  console.log('Entries', entries);
        // console.log('Observer', observer);
        entries.forEach((entry) => {
          // console.log('😍');
          if (entry.isIntersecting) {
            //   console.log('intersecting', entry);
            this.player = new Player('locationsVideo', this.videoOptions);
            this.muteBtn = 'Unmute';
            this.player.play();
            this.player.setMuted(true);
            // const img = entry.target;
            // const src = img.getAttribute('data-lazy');
            // img.setAttribute('src', src);
            // img.classList.add('fade');
            // observer.disconnect();
          } else {
            this.player.pause();
          }
        });
      });
      io.observe(target);
    };
    this.targets.forEach(lazyLoad);
  }
}
