<div class="pageWrapper">
  <div
    style="
      background-color: #50c878;
      padding: 1rem;
      font-size: 1.5rem;
      width: max-content;
      color: white;
      margin: 8rem auto;
      border-radius: 10px;
      text-decoration: none;
    "
  >
    <ng-container *ngIf="isGood">
      <h4>Looks Good!</h4>
    </ng-container>
    <ng-container *ngIf="isVerifying">
      <h4>Verifying {{personEmail}}...</h4>
    </ng-container>
  </div>
</div>
