import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class FormService {
  formErrors = {
    jobNumber: '',
    hoursWorked: '',
    personFirstName: '',
    personLegalNameFirst: '',
    personLastName: '',
    personLegalNameLast: '',
    personEmployeeNumber: '',
    personEmail: '',
    personPhoneNumber: '',
    personMessage: '',
    personSubject: '',
    referredByLegalNameFirst: '',
    referredByLegalNameLast: '',
    referredByEmployeeNumber: ''
  };
  validationMessages = {
    cscBranch: {
      required: 'Branch Name is required.',
      minlength: 'First Name must be minimum  4 character.',
      maxlength: 'First Name must be less than 50 characters.'
    },
    jobNumber: {
      required: 'Job Number is required.',
      minlength: 'Job Number must be minimum 10 characters.',
      maxlength: 'Job Number must be less than 50 characters.'
    },
    hoursWorked: {
      required: 'Hours Worked is required.',
      minlength: 'Hours Worked must be at least 1 hour.',
      maxlength: 'Hours Worked must be less than 99 hours.'
    },
    referredByLegalNameFirst: {
      required: 'Referrer First Name is required.',
      minlength: 'Referrer Name must be minimum  1 character.',
      maxlength: 'Referrer Name must be less than 50 characters.'
    },
    referredByLegalNameLast: {
      required: 'Referrer Last Name is required.',
      minlength: 'Referrer Name must be minimum  1 character.',
      maxlength: 'Referrer Name must be less than 50 characters.'
    },
    referredByEmployeeNumber: {
      required: 'Referrer Employee Number is required.',
      minlength: 'Referrer Employee Number must be minimum  5 characters.',
      maxlength: 'Referrer Employee Number must be less than 50 characters.'
    },
    personLegalNameFirst: {
      required: 'First Name is required.',
      minlength: 'First Name must be minimum  1 character.',
      maxlength: 'First Name must be less than 50 characters.'
    },
    personLegalNameLast: {
      required:  'Last Name is required.',
      minlength: 'Last Name must be greater than 2 characters.',
      maxlength: 'Last Name must be less than 50 characters.'
    },
    personEmployeeNumber: {
      required:  'Employee Number is required.',
      minlength: 'Employee Number must be minimum  5 characters.',
      maxlength: 'Employee Number must be less than 20 characters.'
    },

    personFirstName: {
      required: 'First Name is required.',
      minlength: 'First Name must be greater than 2 characters.',
      maxlength: 'First Name must be less than 50 characters.'
    },
    personLastName: {
      required: 'Last Name is required.',
      minlength: 'Last Name must be greater than 2 characters.',
      maxlength: 'Last Name must be less than 50 characters.'
    },
    personEmail: {
      required: 'Email is required.',
      email: 'Email is formatted incorrectly.',
      pattern: 'Must be a valid email format.'
    },
    personPhoneNumber: {
      required: 'Phone Number is required.',
      minlength: 'Phone Number must be 10 characters.'
    },
    personCellPhone: {
      required: 'Phone Number is required.',
      minlength: 'Phone Number must be 10 characters.'
    },
    personLocation: {
      required: 'Location is required.',
      minlength: 'Location must be 2 characters.',
      maxlength: 'Location cannot be more than 50 characters.'
    },
    personMessage: {
      required: 'Message is required.',
      minlength: 'Message must be minimum  25 characters.',
      maxlength: 'Message cannot be more than 500 characters.'
    },
    personSubject: {
      required: 'Message is required.',
      minlength: 'Message must be minimum  5 characters.',
      maxlength: 'Message cannot be more than 100 characters.'
    }
  };
  constructor() {}

  customPatchValue(data: any, formGroup: FormGroup) {
  //  console.log('PATCH DATA', data);
  //  console.log(formGroup);

    data.map((k: any) => {
    //  console.log('K', k);
      const truthy = Object.keys(k).filter(v => k[v] !== undefined || k[v] !== null || k[v] !== '');
     // console.log(truthy);
      const newObj = {};
      truthy.forEach(v => Object.assign(newObj, {
        [v]: k[v]
      }));
     // console.log(newObj);
    //  console.log(truthy);
      formGroup.patchValue(newObj);
    });
    return formGroup;
  }

  logValidationErrors(group: FormGroup): void {
  //  console.log(group);
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        return this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid) {
          const messages = this.validationMessages[key];
       //   console.log(messages);
        //  console.log(abstractControl.errors);
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              return this.formErrors[key] += messages[errorKey] + '';
            }
          }
        }
      }
    });
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
