import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReadService } from 'src/app/services/read.service';

@Component({
  selector: 'app-services-image-section',
  templateUrl: './services-image-section.component.html',
  styleUrls: ['./services-image-section.component.css'],
})
export class ServicesImageSectionComponent implements OnInit {
  images = [
    {
      path: '../../../../assets/img/services/access_control.jpg',
      phone: '../../../../assets/img/services/access_control_phone.jpg',
      title: 'Access Control',
      overlayText:
        'Verifies the validity of credential utilizing a “Grasp and Confirm” technique at checkpoints. Ensures only authorized personnel access restricted areas.',
    },
    {
      path: '../../../../assets/img/services/ambassadors.jpg',
      phone: '../../../../assets/img/services/ambassadors_phone.jpg',
      title: 'Ambassadors',
      overlayText:
        'Provide guest services and security to university and business campuses.',
    },
    {
      path: '../../../../assets/img/services/armed_officers.jpg',
      phone: '../../../../assets/img/services/armed_officers_phone.jpg',
      title: 'Armed Officers',
      overlayText:
        'A group of professional off-duty peace or police officers and inactive military personnel from law enforcement departments and the armed forces which deliver industry best-practice programs and services suited to the culture and conditions of the clientele.',
    },
    {
      path: '../../../../assets/img/services/bike_patrol.jpg',
      phone: '../../../../assets/img/services/bike_patrol_phone.jpg',
      title: 'Bike Patrol',
      overlayText:
        'Patrols parking lots, garages, festival grounds and other areas to act as a deterrent for criminal activity, provide information to guests, and respond to incidents.',
    },
    {
      path: '../../../../assets/img/services/boxoffice.jpg',
      phone: '../../../../assets/img/services/boxoffice_phone.jpg',
      title: 'Box Office',
      overlayText:
        'Event ticket sales as well as distribution of pre-sold tickets in will-call format. Providing assistance or information for patrons at an automated ticketing station.',
    },
    {
      path: '../../../../assets/img/services/consulting.jpg',
      phone: '../../../../assets/img/services/consulting_phone.jpg',
      title: 'Consulting',
      overlayText:
        'Provide research-driven protocols and procedures to ensure safety, organization and accountability from start to finish for any type of event.',
    },
    {
      path: '../../../../assets/img/services/event_security.jpg',
      phone: '../../../../assets/img/services/event_security_phone.jpg',

      title: 'Crowd Management',
      overlayText:
        'Provide queue line management, crowd flow control, event egress organization, and other crowd management services.',
    },
    {
      path: '../../../../assets/img/services/event_security2.jpg',
      phone: '../../../../assets/img/services/event_security2_phone.jpg',

      title: 'Event Security',
      overlayText:
        'Guest security screening, venue roamer security, stage barricade, and other front-of-house and back-of-house security.',
    },
    {
      path: '../../../../assets/img/services/usher.jpg',
      phone: '../../../../assets/img/services/usher_phone.jpg',

      title: 'Guest Services',
      overlayText:
        'Provide concierge/information desk, elevator/escalator staff, club/VIP suite attendants, and other guest services.',
    },
    {
      path: '../../../../assets/img/services/parking.jpg',
      phone: '../../../../assets/img/services/parking_phone.jpg',

      title: 'Parking',
      overlayText:
        'Inbound/outbound traffic control, flaggers/parkers, parking cashiers, and other parking related services.',
    },
    {
      path: '../../../../assets/img/services/ticket_taking.jpg',
      phone: '../../../../assets/img/services/ticket_taking_phone.jpg',

      title: 'Ticket Taking',
      overlayText:
        'Ensure guests have valid ticket for the event. Reconcile count at the end of shift.',
    },
    {
      path: '../../../../assets/img/services/crowd_management.jpg',
      phone: '../../../../assets/img/services/crowd_management_phone.jpg',

      title: 'Ushering',
      overlayText:
        'Assist guests locating their seats/suites, handle ticket issues and resolve problems, and direct guests in the event of an evacuation.',
    },
  ];
  private behaviorSubject$ = new BehaviorSubject([]);
  public servicesData: Observable<any[]> = this.behaviorSubject$.asObservable();
  rootCollectionAndBranchDoc: any;
  services: any;
  constructor(
    private readService: ReadService
  ) {}

  ngOnInit(): void {
    this.rootCollectionAndBranchDoc = JSON.parse(sessionStorage.getItem(`RootCollectionAndBranchDoc`) as any);

    this.fetchTableDataFn();
  }

  async fetchTableDataFn(){
    console.log('RootCollectionAndBranchDoc', this.rootCollectionAndBranchDoc );

    const i = await this.readService.returnPromiseWhereFn(
      `${this.rootCollectionAndBranchDoc}/websiteContent`,
      'sectionName',
      'services'
      );
      console.log('I', i);

      const [x] = [...i];
    this.services = await this.readService.returnPromiseOrderByFn(
        `${this.rootCollectionAndBranchDoc}/websiteContent/${x.docId}/images`,
        'index',
        'asc'
        );
    console.log('L', this.services);
    // this.services = this.readService.returnObservableOrderByFn(
    //     `${this.rootCollectionAndBranchDoc}/websiteContent/i81h0M5sQoK7JrYwS3Hl/images`,
    //     'index',
    //     'asc'
    //     ).subscribe((l: any) => {

    //       this.behaviorSubject$.next(l);
    //       console.log('L', l);
    //       console.log('Sub', this.services);

    //     });

    }

}
