<div class="pageWrapper">
  <div class="pageHeader">
    <img src="../../../../assets/img/branches/northern-virginia/dc1.png" alt="CSC Staff Briefing" />
  </div>
  <div class="pageTitle">
    <h1>
      <span class="cscGold">CSC </span>
      <span class="cscMidnightBlueText">Northern Virginia</span>
    </h1>
    <span class="divider"> </span>
    <h2>
      <span class="cscMidnightBlueText ">We're </span
      ><span class="cscGold ">Hiring!</span>
    </h2>
  </div>
  <div class="copyIntro">
    <p>CSC Northern Virginia is looking for additional Part-Time Event Staff to join our team! Satellite branch of our CSC Washington DC office, CSC Northern Virginia primary work locations include venues within the Mclean, Tysons, Fairfax and Leesburg, VA area. CSC has a history of delivering exceptional services for over 50 years in events and venues from as small as one guest, to multi-day events hosting hundreds of thousands of patrons. This broad experience has enabled CSC to build a knowledgeable group of professionals with expertise in every crowd management application. Why sit on the sideline when you can be part of the experience? Apply today at csc-usa.com/apply or clicking the below apply now link!
    </p>
    <div class="whereWhen">
        <p class="where"><span class="pHeading">Where:</span> Capital One Hall, 7750 Capital One Tower Rd, McLean, VA  22102</p>
    <p class="when">
      <span class="pHeading">When:</span>
      November 11th from 5pm-7pm
    </p>
    </div>

    <p>
      All interviews will take place in Capital One Hall and should last approx. 15-30mins. Please enter Capital One Hall through the main entrance off of Capital One Dr. S. and look for a CSC Event Staff representative in a yellow jacket to further direct you in the lobby. Parking will be located in the lot on the corner of Scotts Crossing Rd and Capital One Dr. N next to Capital One Tower. Please follow all signage to Capital One Hall.
    </p>

  </div>
  <div class="applyBtn">
    <a
      href="https://hportal.schedulingsite.com/verifyemailAdd/1059"
      target="_blank"
      rel="noopener noreferrer"
      rel="noopener noreferrer"
    >
      <button class="cscGoldBtnInverse" mat-button>
        APPLY NOW!
      </button>
    </a>
  </div>
</div>
