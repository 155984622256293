<div class="esopWrapper">
<div id="iframe_container" class="esop-iframe">
  <iframe loading="lazy" name="ifr" src="https://sway.office.com/s/Odh8eAZ1JI9jFFj0/embed" frameborder="0" marginheight="0" marginwidth="0" max-width="100%" sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts" scrolling="no" style="position: fixed; left: 0; top: 0; right: 0; bottom: 150; background: white; " allowfullscreen mozallowfullscreen msallowfullscreen webkitallowfullscreen
  onload = "document.body.style.height = frames.ifr.document.body.offsetHeight + parseInt(document.getElementById('iframe_container').style.top) + parseInt(document.getElementById('iframe_container').style.bottom) + 'px'"></iframe>
</div>

<!-- <div class="foooter">
  <app-footer></app-footer>
</div> -->
</div>



