import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-experience',
  templateUrl: './our-experience.component.html',
  styleUrls: ['./our-experience.component.css']
})
export class OurExperienceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
