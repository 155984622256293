import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dhs-certified',
  templateUrl: './dhs-certified.component.html',
  styleUrls: ['./dhs-certified.component.css']
})
export class DhsCertifiedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
