import {
  Component,
  Input,
  ViewChild,
  OnInit
} from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';

import {
  AuthService
} from 'src/app/auth/auth.service';

import {
  MatSidenav
} from '@angular/material/sidenav';
import { Router } from '@angular/router';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})

export class NavComponent implements OnInit {
  @Input() drawer: MatSidenav;
  @ViewChild('sidenav') public sidenav: MatSidenav;

  isPhone = false;
  isTablet = false;
  isLaptop = false;
  isDesktop = false;
  isLargeDesktop = false;

  // isHandset$: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  // isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Tablet);

  // isHandset$: Observable < boolean > = this.breakpointObserver.observe([Breakpoints.Handset])
  //   .pipe(
  //     map(result => result.matches),
  //     shareReplay()
  //   );

  constructor(private breakpointObserver: BreakpointObserver,
              private router: Router,
              public authService: AuthService) {}

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.XSmall]) {
      //  console.log('Matches XSmall viewport');
        this.isPhone = true;
        this.isLaptop = false;
        this.isDesktop = false;
        this.isLargeDesktop = false;
      }
      if (state.breakpoints[Breakpoints.Small]) {
     //   console.log('Matches Small viewport');
        this.isTablet = true;
        this.isLaptop = false;
        this.isDesktop = false;
        this.isLargeDesktop = false;
      }
      if (state.breakpoints[Breakpoints.Medium]) {
      //  console.log('Matches Medium  viewport');
        this.isLaptop = true;
        this.isPhone = false;
        this.isTablet = false;
      }
      if (state.breakpoints[Breakpoints.Large]) {
        this.isDesktop = true;
        this.isLaptop = false;
        this.isTablet = false;
      //  console.log('Matches Large viewport');
      }
      if (state.breakpoints[Breakpoints.XLarge]) {
        this.isLargeDesktop = true;
        this.isLaptop = false;
        this.isTablet = false;
      //  console.log('Matches XLarge viewport');
      }
    });
  }
  signOut(){
    this.authService.logout();
  }
}
