<div id="services" class="pageWrapper">
  <div class="contentWrapper">
    <ng-container>
      <div class="servicesSection">
        <div class="servicesCopy">
          <h1>Our  <span class="cscGoldText">Services</span></h1>
          <p>
            CSC's team of experienced managers effectively recruit, vet, hire, and train ambassadors who will represent both you and your venue and event in a professional manner. Its management team, corporate infrastructure, support, training, and quality supervision are what set CSC apart from other crowd management companies. CSC has found that investing its revenue and resources back into clients’ programs assures long-term success over short term profits.
          </p>

          <p>
            Success is based on the continual focus of quality and innovation within the following four key areas: knowledgeable and experienced management, high quality  workforce, world-class training, and cutting-edge technology. CSC’s investment in systems, tools, and personnel come together in a service designed to meet the needs of each client.
          </p>
        </div>
      </div>
    </ng-container>
  </div>

  <app-services-image-section></app-services-image-section>

  <!-- <div class="allServicesWrapper">
     <div class="servicesImageSection">
    <ng-container *ngFor="let x of images">
      <div class="imageSection">
        <div class="imageTitle">
          <p>{{x.title}}</p>
          </div>
      <div class="imageWrapper">
         <img [src]="x.path" alt="{{x.title}}"
         srcset="{{x.path}} 300w, {{x.phone}} 150w"
         >
      </div>
      </div>
    </ng-container>
      </div>
</div> -->
</div>

<app-footer></app-footer>
