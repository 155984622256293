<div class="pageWrapper">
  <div class="pageHeader">
    <img src="../../../../assets/img/branches/inianapolis/lo.jpeg" alt="Lucas Oil Stadium, Indianapolis" />
  </div>
  <div class="pageTitle">
    <h1>
      <span class="cscGold">CSC </span>
      <span class="cscMidnightBlueText">Indianapolis</span>
    </h1>
    <span class="divider"> </span>
    <h2>
      <span class="cscMidnightBlueText ">We're </span
      ><span class="cscGold ">Hiring!</span>
    </h2>
  </div>
  <div class="copyIntro">
    <p>"Wanna get paid while attending Colts games? Sure!? Join our team at Lucas Oil Stadium and work with us at CSC, the leader in crowd management where you can work fun events on your schedule and be automatically entered for a chance to win one of our amazing sweepstakes prizes like a Super Bowl Experience or up to $7,500 cash! Our event staff is one big family, and we are looking to
      add! Get a head start by clicking 'Apply Now' and we'll see you at kickoff! 🏈"
    </p>
    <div class="whereWhen">
        <p class="where"><span class="pHeading">Where:</span> 500 S Capitol Ave, Indianapolis, IN 46225</p>
    <p class="when">
      <span class="pHeading">When:</span>
      Wednesday October 13th from 11 a.m. to 7 p.m.
    </p>
    </div>
    <p class="">There will be staff at the stadium to direct you to the East Club.</p>
    <p>
      The hiring process should take approximately 20 minutes and if you didn't get a chance to Apply online, no worries, we'll do it together when you come to the stadium.
    </p>
    <p>Everyone who comes to the stadium gets a free t-shirt!</p>
  </div>
  <div class="applyBtn">
    <a
      href="https://hportal.schedulingsite.com/verifyemailAdd/26"
      target="_blank"
      rel="noopener noreferrer"
      rel="noopener noreferrer"
    >
      <button class="cscGoldBtnInverse" mat-button>
        APPLY NOW!
      </button>
    </a>
  </div>
</div>
