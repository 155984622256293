import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';


@Directive({
  selector: '[appConfirmEqualValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ConfirmEqualValidatorDirective,
    multi: true
  }]
})
export class ConfirmEqualValidatorDirective implements Validator {
  @Input() appConfirmEqualValidator: string;
  validate(c: AbstractControl): {[key: string]: any} | null{
const controlToCompare = c.parent.get(this.appConfirmEqualValidator);

if (controlToCompare && controlToCompare.value !== c.value){
  return {notEqual: true};
}

return null;
  }
}
