import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { GlobalConstants } from './global-variables';
import { CreateService } from './services/create.service';
import { ReadService } from './services/read.service';
import { SEOService } from './services/seo.service';
import { PrivacyNotificationDialogComponent } from './shared/privacy-notification-dialog/privacy-notification-dialog.component';
declare var google: any;
declare var gtag: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  initialVisit = 'true';
  policyDate: any;
  policyMonthNumber: any;
  policyYear: any;
  policyMonthName: any;
  storedPolicyYear: any;
  storedPolicyMonthNumber: any;
  storedPolicyDate: any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private readService: ReadService,
    private seoService: SEOService,
    private route: ActivatedRoute
  ) {
    const navEndEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-104908383-2', {
        page_path: event.urlAfterRedirects,
      });
    });
  }

  ngOnInit() {
    sessionStorage.setItem('RootEmployeeCollection', JSON.stringify(GlobalConstants.rootEmployeeCollection));
    sessionStorage.setItem('RootCollectionAndBranchDoc', JSON.stringify(GlobalConstants.rootCollectionAndBranchDoc));

    const { meta } = this.route.snapshot.data;
    if (meta) {
      this.seoService.updateTitle(meta.title);
      this.seoService.updateDescription(meta.description);
    }
    this.storedPolicyYear = localStorage.getItem('PolicyYear');
    this.storedPolicyMonthNumber = localStorage.getItem('PolicyMonth');
    this.storedPolicyDate = localStorage.getItem('PolicyDate');

    this.fetchPrivacyPolicyDateFn();
    //  this.createService.setPageTitleFn('CSC USA');
    // const tag = document.createElement('script');
    // tag.src = 'https://www.youtube.com/iframe_api';
    // document.body.appendChild(tag);
  }

  async fetchPrivacyPolicyDateFn() {
    const pDate = await this.readService.returnPromiseWhereFn(
      'cscBranchDirectory/corporate/legal',
      'docId',
      'privacyPolicy'
    );
    pDate.forEach((e) => {
      //   console.log('Policy Date', e);
      this.policyDate = e.policyDate;
      this.policyMonthNumber = e.policyMonthNumber;
      this.policyMonthName = e.policyMonthName;
      this.policyYear = e.policyYear;
    });
    const storedPolicyDate = `${this.storedPolicyMonthNumber}, ${this.storedPolicyDate}, ${this.storedPolicyYear}`;
    //  console.log('storedPolicyDate', storedPolicyDate);
    const currentPolicyDate = `${this.policyMonthNumber}, ${this.policyDate}, ${this.policyYear}`;
    //  console.log('currentPolicyDate', currentPolicyDate);
    if (storedPolicyDate !== currentPolicyDate) {
      this.privacyDialogFn();
    }
  }

  privacyDialogFn() {
    const dialogRef = this.dialog.open(PrivacyNotificationDialogComponent, {
      panelClass: 'editDialog',
      data: {
        policyDate: `Privacy Policy Updated: ${this.policyMonthName} ${this.policyDate}, ${this.policyYear}`,
        class: 'newsletterSignUp',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        //  console.log(res.data);
        localStorage.setItem('PolicyMonth', this.policyMonthNumber);
        localStorage.setItem('PolicyDate', this.policyDate);
        localStorage.setItem('PolicyYear', this.policyYear);
      });
  }
}
