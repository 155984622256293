import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EsopComponent } from './esop/esop.component';

const routes: Routes = [
  {path: '', component: EsopComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: []
})
export class EsopRoutingModule {
  static components = [EsopComponent];
}
