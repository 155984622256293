import { Injectable } from '@angular/core';
import { doc, Firestore, setDoc } from '@angular/fire/firestore';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CreateService {
  constructor(private afs: Firestore, private titleService: Title) {}
  setPageTitleFn(title) {
    this.titleService.setTitle(title);
  }

  createRecordFn(collectionPath: any, docId: any, formData: object){
    console.log(collectionPath);
    console.log(docId);
    console.log(formData);
    const colRef = doc(this.afs, collectionPath, docId);
   setDoc(colRef, formData, { merge: true});

   }
}
