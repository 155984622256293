import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { pluck } from 'rxjs/operators';
import { SEOService } from 'src/app/services/seo.service';
@Component({
  selector: 'app-esop',
  templateUrl: './esop.component.html',
  styleUrls: ['./esop.component.css'],
})
export class EsopComponent implements OnInit {
  readonly articles$ = this.route.data.pipe(pluck('esopPage'));

  constructor(
    private seoService: SEOService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const { meta } = this.route.snapshot.data;
    this.seoService.updateTitle(meta.title);
    this.seoService.updateDescription(meta.description);
  }
}
