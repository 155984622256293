<div class="pageWrapper">

  <div class="pageHeader">
    <ng-container *ngIf="eventAssets | async">
      <ng-container *ngFor="let x of eventAssets | async">
      <img [src]="x.bannerImage" alt="{{x.altText}}">
      </ng-container>
    </ng-container>
  </div>
  <ng-container>

      <div class="pageTitle">
    <h1>
      <span class="cscMidnightBlueText">CSC </span>
      <span class="cscMidnightBlueText">Miami</span>
    </h1>
    <span class="divider"> </span>
    <h2>
      <span class="cscMidnightBlueText ">Now</span
      ><span class="cscMidnightBlueText "> Hiring for Ultra Music Festival 2023!</span>
    </h2>
  </div>

  <ng-container *ngIf="showVideoSection">
     <div class="video">
    <div id="locationsVideo" class="locationsVideoWrapper">
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showVideoSection">
     <div class="video">
    <h2 style="text-align: center;">Loading video...</h2>
    </div>
  </ng-container>



<div class="video">
    <div class="volumeButtons">
    <ng-container *ngIf="showMuteBtn">
  <button class="redBtn" mat-raised-button (click)="muteVolume()" id="muteVolume"><span class="material-icons">
    volume_up
    </span> Mute</button>
    </ng-container>
    <ng-container *ngIf="showUnmuteBtn">
   <button class="greenBtn" mat-raised-button (click)="unmuteVolume()" id="unmuteVolume"><span class="material-icons">
    volume_off
    </span> Unmute</button>
    </ng-container>
  </div>
<!-- <div id="locationsVideo" class="locationsVideoWrapper">
  </div> -->
</div>

  <!-- <div class="video">
    <iframe src="https://player.vimeo.com/video/695898281?h=4612fba397"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  </div> -->
<ng-container *ngIf="showApplyConent">
 <div class="columnsWrapper">

  <section class="countdownClockWrapper">
    <div>
      <ng-container *ngIf="showClock">
        <p class="countdownText" [attr.aria-label]="'countdown clock'">Countdown to Ultra Festival 2023</p>
  <div id="clockdiv">
    <div class="daysWrapper">
      <span [attr.aria-label]="'days to Ultra Fest start date + {{days}}'" class="days">{{days}} </span>
      <div class="smalltext">DAYS</div>
    </div>

    <div class="hoursWrapper">
      <span aria-hidden="true" class="hours">{{hours}}</span>
      <div class="smalltext">HOURS</div>
    </div>

    <div class="minutesWrapper">
      <span aria-hidden="true" class="minutes">{{minutes}}</span>
      <div aria-hidden="true" class="smalltext">MIN</div>
    </div>

    <div class="secondsWrapper">
      <span aria-hidden="true" class="seconds">{{seconds}}</span>
      <div class="smalltext">SEC</div>
    </div>
  </div>
      </ng-container>

      <ng-container *ngIf="!showClock">
         <div class="msgWrapper">

         </div>
      </ng-container>
    </div>
  </section>
  <div class="eventApplyWrapper">
    <div class="eventTitle">
      <h3>Ultra Music Festival 2023</h3>
    </div>
      <div class="copyIntro">
        <p>Come work “The World’s Premier Electronic Music Festival” RESISTANCE Ultra Music Festival 2023. CSC Miami is now hiring <strong>Licensed Security Guards</strong> to work this iconic Downtown Miami event on March 24, 25 & 26. Apply today and be part of the experience!”</p>

  </div>
  <div class="applyBtn">
    <a
      href="https://hportal.schedulingsite.com/verifyemailAdd/40"
      target="_blank"
      rel="noopener noreferrer"
      rel="noopener noreferrer"
    >
      <button class="cscGoldBtnInverse" mat-raised-button>
        APPLY NOW!
      </button>
    </a>
  </div>


  </div>


  </div>
</ng-container>

  </ng-container>

</div>

