<div class="pageWrapper">
  <ng-container *ngIf="!showMessage">
    <button mat-raised-button class="redBtn" (click)="deleteNewsletterRegistration()">Confirm Unsubscription</button>
  </ng-container>

  <ng-container *ngIf="showMessage">
    <div>
    <p>{{isMessage}}</p>
    </div>
  </ng-container>
</div>
