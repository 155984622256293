import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UseUsService {

  constructor() { }

  newsletterSignUpEmailFn(){
    return [
      {
        cssWrapperClass: 'personEmail',
        controlLabel: 'Email',
        controlName: 'personEmail',
        controlType: 'email',
        valueType: 'email',
        placeholder: 'Email',
        validators: {
        required: true,
        pattern: this.emailPatternFn(),
        minlength: 5,
        maxlength: 100
        }
        },
    ];
  }

  emailPatternFn(){
    return ('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
  }
}
