<div class="pageWrapper">
  <!-- <div class="pageHeader">
    <img src="https://firebasestorage.googleapis.com/v0/b/csc-usa-prod.appspot.com/o/cscBranchDirectory%2Fcorporate%2FbranchHiringEvents%2Fphoenix%2Fbanner_image_mullet_arena.png?alt=media&token=ce2f1612-f876-4543-9818-78158109c36b" alt="Mullet arena" />
  </div> -->
  <div class="pageHeader">
    <ng-container *ngIf="stadiumAssets | async">
      <ng-container *ngFor="let x of stadiumAssets | async">
      <img [src]="x.bannerImage" alt="{{x.altText}}">
      </ng-container>
    </ng-container>
    <!-- <img src="https://firebasestorage.googleapis.com/v0/b/csc-usa-prod.appspot.com/o/cscBranchDirectory%2Fcorporate%2FbranchHiringEvents%2Fphoenix%2FAZ_Mullet_Arena_Hero.jpg?alt=media&token=342d26d9-c242-4392-80a0-a71223c37743" alt="Mullet arena" /> -->
  </div>
  <ng-container>

      <div class="pageTitle">
    <h1>
      <span class="cscMidnightBlueText">CSC </span>
      <span class="cscMidnightBlueText">Phoenix</span>
    </h1>
    <span class="divider"> </span>
    <h2>
      <span class="cscMidnightBlueText ">Now</span
      ><span class="cscMidnightBlueText "> Hiring for Super Bowl LVII!</span>
    </h2>
  </div>

  <ng-container *ngIf="showVideoSection">
     <div class="video">
    <div id="locationsVideo" class="locationsVideoWrapper">
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showVideoSection">
     <div class="video">
    <h2 style="text-align: center;">Loading video...</h2>
    </div>
  </ng-container>



<div class="video">
    <div class="volumeButtons">
    <ng-container *ngIf="showMuteBtn">
  <button class="redBtn" mat-raised-button (click)="muteVolume()" id="muteVolume"><span class="material-icons">
    volume_up
    </span> Mute</button>
    </ng-container>
    <ng-container *ngIf="showUnmuteBtn">
   <button class="greenBtn" mat-raised-button (click)="unmuteVolume()" id="unmuteVolume"><span class="material-icons">
    volume_off
    </span> Unmute</button>
    </ng-container>
  </div>
<!-- <div id="locationsVideo" class="locationsVideoWrapper">
  </div> -->
</div>

  <!-- <div class="video">
    <iframe src="https://player.vimeo.com/video/695898281?h=4612fba397"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  </div> -->

  <div class="columnsWrapper">
  <!-- <div class="stateFair">
    <div class="eventTitle">
      <h3>MULLET ARENA</h3>
    </div>
    <div class="imageWrapper">
   <img src="https://firebasestorage.googleapis.com/v0/b/csc-usa-prod.appspot.com/o/cscBranchDirectory%2Fcorporate%2FbranchHiringEvents%2Fphoenix%2Fmullet_arena.png?alt=media&token=1b2ca849-9356-4175-a71b-c7d8db61acd2" alt="MULLET ARENA">
      </div>
      <div class="copyIntro">
    <p>Join the experience this Fall at ASU's new multipurpose center - Mullett Arena!
    </p>
      <p>Now hiring Event Security positions for some spectacular upcoming events including ASU hockey and gymnastics, community events, as well as professional hockey games for the Arizona Coyotes.
    </p>
  </div>
  <div class="applyBtn">
    <a
      href="https://hportal.schedulingsite.com/verifyemailAdd/504"
      target="_blank"
      rel="noopener noreferrer"
      rel="noopener noreferrer"
    >
      <button class="cscGoldBtnInverse" mat-raised-button>
        APPLY NOW!
      </button>
    </a>
  </div>
  </div> -->

  <div class="superBowl">
    <div class="eventTitle">
      <h3>SUPER BOWL LVII</h3>
    </div>
    <div class="imageWrapper">
      <img src="https://firebasestorage.googleapis.com/v0/b/csc-usa-prod.appspot.com/o/corporate%2Fimages%2Fwebsite%2FbranchHiringEvents%2Fphoenix%2FSB57_Primary_RGB_250.png?alt=media&token=ec6e5861-5ddf-45bf-9fac-c44348173eac" alt="Super Bowl 2022">
    </div>
      <div class="copyIntro">
        <p>Join our team and be a part of the Super Bowl LVII experience at State Farm Stadium! Now hiring Event Staff, Access Control, Security Guards and MORE! Positions starting at $18/hour.</p>

<p>For every hour worked during the Super Bowl, you can increase your chance to win prizes and incentives! Don’t miss this momentous event. Interviews will be held until December 1st!</p>

    <!-- <p>Work Super Bowl LVII! CSC Phoenix is hiring for multiple positions including Event Staff, Security Officer and much more.
    </p>

    <p>
      For every hour worked during Super Bowl you increase your chance to win cash/prizes.  Why sit on the sideline when you can be part of the experience?
    </p>
    <h3>
      Saturday, November 12th @ State Farm Stadium.
    </h3>
    <h3>10am - 3pm</h3> -->
  </div>
  <div class="applyBtn">
    <a
      href="https://hportal.schedulingsite.com/verifyemailAdd/15"
      target="_blank"
      rel="noopener noreferrer"
      rel="noopener noreferrer"
    >
      <button class="cscGoldBtnInverse" mat-raised-button>
        APPLY NOW!
      </button>
    </a>
  </div>
  </div>
  </div>
  </ng-container>

</div>
