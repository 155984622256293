<div class="pageWrapper">
  <!-- <div class="pageHeader">
      <img src="https://firebasestorage.googleapis.com/v0/b/csc-usa-prod.appspot.com/o/cscBranchDirectory%2Fcorporate%2FbranchHiringEvents%2Fphoenix%2Fbanner_image_mullet_arena.png?alt=media&token=ce2f1612-f876-4543-9818-78158109c36b" alt="Mullet arena" />
    </div> -->
  <div class="pageHeader">
    <!-- <ng-container *ngIf="stadiumAssets | async">
        <ng-container *ngFor="let x of stadiumAssets | async">
        <img [src]="x.bannerImage" alt="{{x.altText}}">
        </ng-container>
      </ng-container> -->

    <a href=" https://hportal.schedulingsite.com/verifyemailAdd/44" target="_blank" rel="noopener noreferrer"
      rel="noopener noreferrer">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/csc-usa-prod.appspot.com/o/corporate%2Fimages%2Fwebsite%2FbranchHiringEvents%2Flasuperbowl2023%2FCSC_SuperbowlLVIII_WebsiteHeader%20(9).png?alt=media&token=921f780d-5221-4e1e-a1b6-42a70a295417"
        alt="SUPER BOWL LVIII" />
    </a>
  </div>
  <ng-container>

    <div class="pageTitle">
      <h1>
        <span class="cscMidnightBlueText">CSC </span>
        <span class="cscMidnightBlueText">Las Vegas</span>
      </h1>
      <span class="divider"> </span>
      <!-- <h2>
                <span class="cscMidnightBlueText ">Now</span><span class="cscMidnightBlueText "> Hiring for Super Bowl
                    LVIII!</span>
            </h2> -->
    </div>


    <!-- <div class="video">
          <iframe src="https://player.vimeo.com/video/695898281?h=4612fba397"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div> -->

    <div class="columnsWrapper">
      <!-- <div class="stateFair">
          <div class="eventTitle">
            <h3>MULLET ARENA</h3>
          </div>
          <div class="imageWrapper">
         <img src="https://firebasestorage.googleapis.com/v0/b/csc-usa-prod.appspot.com/o/cscBranchDirectory%2Fcorporate%2FbranchHiringEvents%2Fphoenix%2Fmullet_arena.png?alt=media&token=1b2ca849-9356-4175-a71b-c7d8db61acd2" alt="MULLET ARENA">
            </div>
            <div class="copyIntro">
          <p>Join the experience this Fall at ASU's new multipurpose center - Mullett Arena!
          </p>
            <p>Now hiring Event Security positions for some spectacular upcoming events including ASU hockey and gymnastics, community events, as well as professional hockey games for the Arizona Coyotes.
          </p>
        </div>
        <div class="applyBtn">
          <a
            href="https://hportal.schedulingsite.com/verifyemailAdd/504"
            target="_blank"
            rel="noopener noreferrer"
            rel="noopener noreferrer"
          >
            <button class="cscGoldBtnInverse" mat-raised-button>
              APPLY NOW!
            </button>
          </a>
        </div>
        </div> -->

      <div class="superBowl">
        <div class="eventTitle">
          <h1>
            NOW <span class="cscMidnightBlueText" style="font-weight: bolder;"> HIRING </span> FOR<br>
            <!-- <span  style="font-weight: bolder;">SUPER BOWL LVIII </span> -->
            <span class="cscMidnightBlueText" style="font-weight: bolder;">SUPER BOWL LVIII </span> AT ALLEGIANT STADIUM
          </h1>
        </div>
        <!-- <div class="imageWrapper">
                    <img src="https://firebasestorage.googleapis.com/v0/b/csc-usa-prod.appspot.com/o/corporate%2Fimages%2Fwebsite%2FbranchHiringEvents%2Flasuperbowl2023%2FSuper_Bowl_LVIII_logo.png?alt=media&token=d64bb59b-520c-4bd3-a2fb-cb76fe942b7f"
                        alt="Super Bowl 2023">
                 
                </div> -->
        <div class="copyIntro" style="text-align: center;">
          <!-- <p class="join-content">Join OUR Team at CSC Super Bowl LVIII <strong>HIRING EVENT</strong> </p> -->
          <!-- <p class="join-content">Wednesday <strong>October 25th</strong>, 12pm to 7pm</p>
             <p class="join-content"><strong> Allegiant Stadium </strong></p>
             <p class="join-content">Team Member HQ Building (across from stadium) </p>
              <p><a style="font-size:21px;" href="https://maps.app.goo.gl/YthPjV9ABSZrtYjs5" target="_blank" rel="noopener noreferrer"
               rel="noopener noreferrer"> 5525 Polaris Ave, Las Vegas NV 89118 </a> </p> -->
          <!-- <p class="join-content">Monday, <strong>December 4th,</strong> 3pm to 7pm</p>
          <p><strong>Allegiant Stadium</strong></p>
          <p class="join-content">Team member HQ Building (across from stadium)</p>
          <p class="join-content">5525 Polaris Avenue, Las Vegas, NV 89118</p> -->
          <p class="join-content">Now <strong>HIRING</strong>  </p>
          <p class="join-content">Event Staff, Access Control, Security Guards & MORE! </p>
          <p class="join-content">Positions starting at $20/hour.</p>
          <div class="applyBtn">
            <a href=" https://hportal.schedulingsite.com/verifyemailAdd/44" target="_blank" rel="noopener noreferrer"
              rel="noopener noreferrer">
              <button class="cscGoldBtnInverse" mat-raised-button>
                APPLY NOW!
              </button>
            </a>
          </div>
          <!-- <p class="join-content"><strong> PLEASE SELECT DECEMBER 4TH AS YOUR INTERVIEW DATE TO  FINALIZE REGISTRATION</strong></p>
            <p class="join-content">Walk-ups welcomed Day of Event</p> -->
            <p class="join-content">Don't Miss Out….Make <strong>SUPER BOWL LVIII</strong> History with
              <strong>CSC</strong> Las Vegas!</p>
        
          <div class="applyBtn">
            <a href=" https://hportal.schedulingsite.com/verifyemailAdd/44" target="_blank" rel="noopener noreferrer"
              rel="noopener noreferrer">
              <button class="cscGoldBtnInverse" mat-raised-button>
                APPLY NOW!
              </button>
            </a>
          </div>
          
        </div>
      </div>
      <div class="video">
        <div id="locationsVideo" class="locationsVideoWrapper">



          <video width="100%" height="auto" controls autoplay loop [muted]="'muted'">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/csc-usa-prod.appspot.com/o/corporate%2Fimages%2Fwebsite%2FbranchHiringEvents%2Flasuperbowl2023%2FGeneric%20Super%20Bowl%20Ad%20SB%20Vegas_supersundayURL.mp4?alt=media&token=ec3f37d7-584c-43b8-a7c4-8291e453ce26"
              type="video/mp4">
          </video>



        </div>
      </div>
      <!-- Vimeo video section  -->
      <!-- <ng-container *ngIf="showVideoSection">
                  <div class="video">
                      <div id="locationsVideo" class="locationsVideoWrapper">
                      </div>
                  </div>
              </ng-container> -->
      <!-- <ng-container *ngIf="!showVideoSection">
        <div class="video">
          <h2 style="text-align: center;">Loading video...</h2>
        </div>
      </ng-container> -->



      <div class="video" style="display: none;">
        <div class="volumeButtons">
          <ng-container *ngIf="showMuteBtn">
            <!-- <button class="redBtn" mat-raised-button (click)="muteVolume()" id="muteVolume"><span
                class="material-icons">
                volume_up
              </span> Mute</button> -->
          </ng-container>
          <ng-container *ngIf="showUnmuteBtn">
            <button class="greenBtn" mat-raised-button (click)="unmuteVolume()" id="unmuteVolume"><span
                class="material-icons">
                volume_off
              </span> Unmute</button>
          </ng-container>
        </div>
        <!-- <div id="locationsVideo" class="locationsVideoWrapper">
              </div> -->
      </div>

    </div>
  </ng-container>

</div>