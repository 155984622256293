import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReadService } from '../../services/read.service';
@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.css'],
})
export class FormDialogComponent implements OnInit {
  formConfig: any;
  patchValues: any;
  firestoreRecordPath: any;
  docId: any;
  buttonValue: any;
  showEmailExistsMsg: boolean;
  emailExistsMsg: string;
  class: any;

  constructor(
    public dialog: MatDialog,
    private readService: ReadService,
    private router: Router,
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.formConfig = this.data.formConfig;
    this.patchValues = this.data?.patchValues;
    this.class = this.data?.class;
  }

  receivedDynamicDataFn(e: any) {
    //  console.log('E', e);
    if (Object.keys(e)?.length) {
      try {
        this.dialogRef.close({ data: e });
      } catch (error) {
        // console.log('Error', error);
      }
    } else {
      //   console.log('IS STRING');
      this.dialogRef.close({ data: e });
    }
  }

  blurEvent(e: any) {
    //  console.log('E', e);
    //  console.log('BLUR $E', e);
  }

  // goToProfile() {
  //   this.dialogRef.close();
  //   this.router.navigateByUrl('/employee/dashboard', {
  //     state: {
  //       docId: this.docId
  //     }
  //   });
  // }
}
