import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReadService } from 'src/app/services/read.service';
import { DeleteService } from 'src/app/services/delete.service';

@Component({
  selector: 'app-email-unsubscribe',
  templateUrl: './email-unsubscribe.component.html',
  styleUrls: ['./email-unsubscribe.component.css'],
})
export class EmailUnsubscribeComponent implements OnInit {
  email: string;
  personEmail: any;
  docId: any;
  showMessage = false;
  isMessage: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deleteService: DeleteService,
    private readService: ReadService
  ) {
    const params = { ...this.route.snapshot.queryParams };
    this.docId = params.s;
    //  console.log('UNSUB', this.docId);
    delete params.s;
    this.router.navigate([], { queryParams: params });
  }

  ngOnInit() {
    // this.route.queryParams
    //   .subscribe(params => {
    //     console.log(params);
    //     this.docId = params.s;
    //     console.log(this.docId);
    //   }
    // );

    this.fetchUserDetailsFn();
  }

  async fetchUserDetailsFn() {
    const p = await this.readService.returnPromiseWhereFn(
      'cscNewsletterSignup',
      'docId',
      this.docId
    );
    //  console.log('P', p);
    p.map(({ personEmail }) => {
      this.personEmail = personEmail;
    });
  }

  deleteNewsletterRegistration() {
    try {
      this.deleteService.deleteRecordFn(
        'cscNewsletterSignup',
        this.personEmail
      );
      this.showMessage = true;
      this.isMessage = `${this.personEmail} has been succesfully unsubscribed.`;
    } catch (error) {
      this.showMessage = true;
      this.isMessage =
        'There has been an error, please contact webmaster@csc-usa.com';
    }
  }
}
